import React from "react";
import { css } from "linaria";
import PageContent from "components/page/PageContent";
import GradientLink from "components/common/GradientLink";
import {
  FancyTitle,
  MainParagraph,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { TextContainer } from "components/rhombus-UI/theme/containers";
import RhombusButton from "components/common/RhombusButton";

const contentClass = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  column-gap: 35px;
  padding: 5rem 0px;
  @media (max-width: 1050px) {
    grid-template-columns: unset;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
  }
`;

const textContainer = css`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1050px) {
    grid-row: 1;
  }
`;

const textClass = css`
  font-weight: bold;
  font-size: 32px;
  max-width: 445px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1050px) {
    min-height: unset;
    padding-bottom: 35px;
  }
`;

const buttonContainerClass = css`
  display: flex;
  flex-direction: column;
  margin-top: 2.5rem;
  h2 {
    margin-bottom: 1.25rem;
  }
`;

const buttonWrapper = css`
  display: flex;
  gap: 1.25rem;
`;

export default function VideoSection() {
  return (
    <section>
      <PageContent>
        <div className={contentClass}>
          <div
            className="wistia_responsive_padding"
            style={{ padding: "56.25% 0 0 0", position: "relative" }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                height: "100%",
                left: "0",
                position: "absolute",
                top: "0",
                width: "100%",
              }}
            >
              <span
                className="wistia_embed wistia_async_ttf60d01br popover=true popoverAnimateThumbnail=true videoFoam=true"
                style={{
                  display: "inline-block",
                  height: "100%",
                  position: "relative",
                  width: "100%",
                }}
              >
                &nbsp;
              </span>
            </div>
          </div>
          <div className={textContainer}>
            <TextContainer width="600px">
              <FancyTitle>
                Your Security Managed From the Palm of Your Hand
              </FancyTitle>
              <MainParagraph>
                Rhombus’ top-rated mobile app allows you to protect your
                organization from anywhere at anytime. Receive notifications,
                view footage, and share video with first responders in seconds.
              </MainParagraph>
              <div className={buttonContainerClass}>
                <TitleSmall>Get the Mobile App</TitleSmall>
                <div className={buttonWrapper}>
                  <RhombusButton
                    type="secondary"
                    path="https://apps.apple.com/us/app/rhombus-systems/id1237841963"
                    useHref
                    target="_blank"
                    title="iOS"
                  />
                  <RhombusButton
                    type="secondary"
                    path="https://play.google.com/store/apps/details?id=com.rhombussystems.rhombus"
                    useHref
                    target="_blank"
                    title="Android"
                  />
                </div>
              </div>
            </TextContainer>
          </div>
        </div>
      </PageContent>
    </section>
  );
}
