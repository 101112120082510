import TrialBanner from "components/common/footer-banners/TrialBanner";
import IndustryLeadersCards from "components/common/sliders/IndustryLeadersCards";
import React from "react";

import InstantDemoHeader from "./InstantDemoHeader";
import VideoSection from "./VideoSection";

export default function InstantDemoPage() {
  return (
    <>
      <InstantDemoHeader />
      <VideoSection />
      <IndustryLeadersCards color="var(--nuetral-100)" />
      <TrialBanner />
    </>
  );
}
