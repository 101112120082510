import { css, cx } from "linaria";
import PropTypes from "prop-types";
import React from "react";

import Link from "./Link";

const linkClass = css`
  font-size: 14px;
  padding: 1rem 2rem;
  border-radius: 0.625rem;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
  transition: all 500ms ease;

  &:hover {
    text-decoration: none;
  }

  div {
    transition: all 500ms ease;
  }
`;

const style = {
  whiteBackground: cx(
    linkClass,
    css`
      display: block;
      background: white;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;

      border: solid 1px transparent;
      background-origin: border-box;
      background-clip: padding-box, border-box;
      background-image: linear-gradient(white, white),
        linear-gradient(to left, var(--teal-500), var(--blue-500));

      div {
        display: inline-block;
        background-image: linear-gradient(
          to left,
          var(--teal-500),
          var(--blue-500)
        );
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &:hover {
        box-sizing: border-box;
        background-image: linear-gradient(
          to left,
          var(--teal-500),
          var(--blue-500)
        );

        div {
          -webkit-text-fill-color: var(--nuetral-100);
        }
      }
    `
  ),
  whiteBackgroundBlackText: cx(
    linkClass,
    css`
      display: block;
      background: white;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;

      background: white;
      border: solid 1px transparent;
      background-origin: border-box;
      background-clip: padding-box, border-box;
      background-image: linear-gradient(white, white),
        linear-gradient(to left, var(--teal-500), var(--blue-500));

      div {
        display: inline-block;
        color: black;
      }
      &:hover {
        box-sizing: border-box;
        background-image: linear-gradient(to left, #00c1de, var(--blue-500));

        div {
          -webkit-text-fill-color: white;
        }
      }
    `
  ),
  creamBackground: cx(
    linkClass,
    css`
      display: block;
      background: white;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;

      background: white;
      border: solid 1px transparent;
      background-origin: border-box;
      background-clip: padding-box, border-box;
      background-image: linear-gradient(var(--nuetral-100), var(--nuetral-100)),
        linear-gradient(to left, var(--teal-500), var(--blue-500));
      div {
        display: inline-block;
        background-image: linear-gradient(
          to left,
          var(--teal-500),
          var(--blue-500)
        );
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &:hover {
        box-sizing: border-box;
        background-image: linear-gradient(
          to left,
          var(--teal-500),
          var(--blue-500)
        );

        div {
          -webkit-text-fill-color: var(--nuetral-100);
        }
      }
    `
  ),
  greyBackground: cx(
    linkClass,
    css`
      display: block;
      background: white;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;

      background: white;
      border: solid 1px transparent;
      background-origin: border-box;
      background-clip: padding-box, border-box;
      background-image: linear-gradient(#ececec, #ececec),
        linear-gradient(to left, var(--teal-500), var(--blue-500));
      div {
        display: inline-block;
        background-image: linear-gradient(
          to left,
          var(--teal-500),
          var(--blue-500)
        );
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      &:hover {
        box-sizing: border-box;
        display: block;
        background-image: linear-gradient(
          to left,
          var(--teal-500),
          var(--blue-500)
        );

        div {
          -webkit-text-fill-color: #ececec;
        }
      }
    `
  ),
  gradientBackground: cx(
    linkClass,
    css`
      box-sizing: border-box;
      display: block;
      background-image: linear-gradient(to left, #00c1de, var(--blue-500));

      div {
        display: inline-block;
        color: var(--nuetral-100);
      }
    `
  ),
  whiteBorder: cx(
    linkClass,
    css`
      border: solid 1px white;
      color: white;
      &:hover {
        display: block;
        background: white;
        div {
          display: inline-block;
          background-image: linear-gradient(
            to left,
            var(--teal-500),
            var(--blue-500)
          );
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    `
  ),
};

/**
 * Link styled to look like a round button.
 * Has special handling for internal links (they open w/o a full page load).
 */
class GradientLink extends React.PureComponent {
  render() {
    return (
      <Link
        {...this.props}
        className={cx(style[this.props.appearance], this.props.className)}
      >
        <div>{this.props.text}</div>
      </Link>
    );
  }
}

GradientLink.propTypes = {
  appearance: PropTypes.oneOf([
    "whiteBackground",
    "whiteBackgroundBlackText",
    "creamBackground",
    "greyBackground",
    "gradientBackground",
    "whiteBorder",
  ]).isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

GradientLink.defaultProps = {
  className: null,
};

export default GradientLink;
