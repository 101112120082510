import PageContent from "components/page/PageContent";
import { TextContainer } from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { css } from "linaria";
import React from "react";

const contentContainer = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 0px;
  column-gap: 35px;
  min-height: 90vh;
  @media (max-width: 1050px) {
    grid-template-columns: unset;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    padding-bottom: 0;
  }
`;

export default function InstantDemoHeader() {
  return (
    <section
      style={{
        background: "var(--gradient-light)",
      }}
    >
      <PageContent>
        <div className={contentContainer}>
          <TextContainer width="600px">
            <FancyPageTitle>
              Simple, Smart, and Powerful Security for Any Organization
            </FancyPageTitle>
            <MainParagraph>
              Rhombus delivers a best-in-class physical security platform with
              high-resolution video, in-depth sensor insights, and cutting-edge
              AI analytics. Deploy and securely manage unlimited devices from a
              unified and secure web-based console.
            </MainParagraph>
          </TextContainer>
          <div
            className="wistia_responsive_padding"
            style={{ padding: "56.25% 0 0 0", position: "relative" }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                height: "100%",
                left: "0",
                position: "absolute",
                top: "0",
                width: "100%",
              }}
            >
              <span
                className="wistia_embed wistia_async_9tswf8jldn popover=true popoverAnimateThumbnail=true videoFoam=true"
                style={{
                  display: "inline-block",
                  height: "100%",
                  position: "relative",
                  width: "100%",
                }}
              >
                &nbsp;
              </span>
            </div>
          </div>
        </div>
      </PageContent>
    </section>
  );
}
